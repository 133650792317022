








import {Vue, Prop, Ref, Watch, Component} from 'vue-property-decorator'

import qrcode from 'qrcode'

@Component({
    name: 'QrCode',
})
export default class QrCode extends Vue {
    @Prop({type: String, required: true})
    private readonly value!: string

    @Ref()
    private readonly canvas!: HTMLCanvasElement

    fullscreen = false
    fullscreenWidth = Math.min(window.innerWidth, window.outerWidth)

    mounted(): void {
        this.createQrCode()
    }

    @Watch('value')
    @Watch('fullscreen')
    createQrCode(): void {
        // readability could be improved by using kanji mode for encoding
        // https://www.npmjs.com/package/qrcode#encoding-modes
        qrcode.toCanvas(this.canvas, this.value, {
            errorCorrectionLevel: 'M',
            margin: 2,
            width: this.fullscreen ? this.fullscreenWidth : 92,
        })
    }

    toggleFullscreen(): void {
        this.fullscreen = !this.fullscreen
    }
}
