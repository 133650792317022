

















import {Component, Prop, Model, Ref, Vue} from 'vue-property-decorator'

import StationSelect from '@/components/form/StationSelect.vue'

export type MenuFormStationSelectOptions = {
    id?: string
    hint?: string
    placeholder?: string
    hideLabel?: boolean
    type?: DbiApi.HafasLocationRequest['type']
    clearOnOpen?: boolean
    forceSelection?: boolean
    resetable?: boolean
    products?: number
}

const OPTION_DEFAULTS = {
    id: 'station',
    itemTitle: 'name',
    type: 'ALL',
    clearOnOpen: true,
    forceSelection: false,
    instantFocus: true,
    name: 'station-select',
}

@Component({
    name: 'MenuFormStationSelect',
    components: {
        StationSelect,
    },
})
export default class MenuFormStationSelect extends Vue {
    @Model('input')
    private readonly value!: Dbi.Station | null

    @Prop({type: Object})
    private readonly options!: MenuFormStationSelectOptions

    @Ref()
    private readonly legend!: HTMLLegendElement

    @Ref()
    private readonly select!: StationSelect

    open = false

    get station(): Dbi.Station | null {
        return this.value
    }

    set station(station: Dbi.Station | null) {
        this.$emit('input', station)
        this.close()
    }

    get innerOptions(): MenuFormStationSelectOptions {
        return Object.assign({}, OPTION_DEFAULTS, this.options)
    }

    onClick(): void {
        this.open = true
    }

    close(): void {
        this.open = false
    }

    reset(): void {
        this.station = this.$dbiStore.state.homeStation
    }
}
