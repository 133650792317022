
import {FunctionalComponentOptions} from 'vue'
import {mergeData} from 'vue-functional-data-merge'

const component: FunctionalComponentOptions = {
    name: 'PageTitle',
    functional: true,
    render(createElement, {data, children}) {
        return createElement('span', mergeData(data, {staticClass: 'sector'}), children)
    },
}
export default component
