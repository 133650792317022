




























import {Component, Prop, Ref, Watch, Mixins} from 'vue-property-decorator'

import TrainRouteRow from '@/components/trainDetail/TrainRouteRow.vue'

import {isTrafficType} from '@/helper'
import {findTrainPosition} from '@common/helper'
import TrainDetailMixin from '@/components/trainDetail/TrainDetailMixin'

@Component({
    name: 'TrainDetailRoute',
    components: {
        TrainRouteRow,
    },
})
export default class TrainDetailRoute extends Mixins(TrainDetailMixin) {
    @Ref()
    private readonly tbody!: HTMLTableSectionElement

    @Prop({type: [HTMLElement, Window], default: () => window})
    readonly scrollElement!: HTMLElement | Window

    isTrafficType = isTrafficType

    get route(): Dbi.TrainDetailStop[] | null {
        const route = this.trainData.details?.route
        if (!route) {
            return null
        }

        findTrainPosition(route)
        return route
    }

    @Watch('route')
    scrollToCurrentPosition(route: Dbi.TrainDetailStop[] | null): void {
        if (!(route && route.length)) {
            return
        }
        this.$nextTick(() => {
            const {$el, tbody} = this
            const trainPosition = tbody.querySelector('#train-position')
            if (trainPosition) {
                this.scrollElement.scrollTo({
                    top: (trainPosition as HTMLElement).offsetTop - ($el as HTMLElement).offsetTop,
                    behavior: 'smooth',
                })
            }
        })
    }
}
