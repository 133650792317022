














import {Component, Mixins} from 'vue-property-decorator'

import DbiMessages from '@common/components/misc/DbiMessages.vue'
import TrainDetailMixin from '@/components/trainDetail/TrainDetailMixin'

@Component({
    name: 'TrainDetailTp',
    components: {
        DbiMessages,
    },
})
export default class TrainDetailTp extends Mixins(TrainDetailMixin) {
    get messages(): Dbi.TpMessage[] | null {
        return this.trainData.details?.tp || null
    }
}
