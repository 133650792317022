
import type {FunctionalComponentOptions, VNode} from 'vue'

const component: FunctionalComponentOptions<{
    wagon: Dbi.Wagon
    connection?: {train: string; destination: string}
}> = {
    name: 'Wagon',
    functional: true,
    props: {
        wagon: {
            type: Object,
            required: true,
        },
        connection: {
            type: Object,
        },
    },
    render(createElement, context) {
        const {
            connection,
            wagon,
            wagon: {engineType, orderNumber, equipment, first, last, classes},
        } = context.props

        const wrapperClass = [engineType ? 'engine' : 'wagon']
        first && wrapperClass.push('start')
        last && wrapperClass.push('end')
        engineType && wrapperClass.push(`type-${engineType}`)

        function getWagonIcon() {
            if (!engineType && classes) {
                return [
                    classes.map((clazz) => createElement('div', {staticClass: `class bg-${clazz}`})),
                    createElement('div', {staticClass: 'number'}, orderNumber),
                ]
            }
            else if (engineType !== 'loco') {
                const bg = classes?.length ? classes[0] : 'engine'
                return createElement('div', {staticClass: `number bg-${bg}`}, orderNumber)
            }
        }

        return (
            <li class={wrapperClass}>
                <div class="wagon-icon" style={{height: `${wagon.length}px`}}>
                    {getWagonIcon()}
                </div>

                <div class="equipment-wrapper">
                    {engineType && connection && (
                        <p class="connection">
                            {connection.train} nach <strong>{connection.destination}</strong>
                        </p>
                    )}
                    <ul class="equipment-list">
                        {equipment.map((equipment) => (
                            <li>
                                <svg-sprite icon={`equipment-${equipment}`} />
                            </li>
                        ))}
                    </ul>
                </div>
            </li>
        ) as VNode
    },
}
export default component
