
import {FunctionalComponentOptions, VNode} from 'vue'

import {displayTime} from '@common/filters'

const component: FunctionalComponentOptions<{
    time: Dbi.TimeEntry
    type: string
}> = {
    name: 'TrainDetailCertificateTime',
    functional: true,
    props: {
        time: {
            type: Object,
        },
        type: {
            type: String,
            required: true,
        },
    },
    render(createElement, context) {
        const {time, type} = context.props
        if (!time) {
            return []
        }
        const {soll, diff, prog, canceled} = time
        const delayVariant = !canceled && !!diff
        const content = [
            <div class="row border">
                <div>
                    <dt>Geplante {type}</dt>
                    <dd>
                        {displayTime(soll)}
                        {canceled && <span class="canceled">ausgefallen</span>}
                    </dd>
                </div>

                {delayVariant && (
                    <div>
                        <dt>Verspätung</dt>
                        <dd class="delay">{diff} Minuten</dd>
                    </div>
                )}
            </div>,
        ]

        delayVariant &&
            content.push(
                <div class="border">
                    <dt>Tatsächliche {type}</dt>
                    <dd>{displayTime(prog)}</dd>
                </div>,
            )

        return content as VNode[]
    },
}
export default component
