


















import {Vue, Component, Watch, PropSync} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import TabNavigation from '@common/components/ui/TabNavigation.vue'
import TrainDetailStation from '@/components/trainDetail/TrainDetailStation.vue'

import TrainDetailRoute from '@/components/trainDetail/TrainDetailRoute.vue'
import TrainDetailWagonOrder from '@/components/trainDetail/TrainDetailWagonOrder.vue'
import TrainDetailConnections from '@/components/trainDetail/TrainDetailConnections.vue'
import TrainDetailHim from '@/components/trainDetail/TrainDetailHim.vue'
import TrainDetailTp from '@/components/trainDetail/TrainDetailTp.vue'
import TrainDetailCertificate from '@/components/trainDetail/TrainDetailCertificate.vue'

import type {TabNavigationLink} from '@common/components/ui/TabNavigation.vue'

import {Subscription} from '@/decorators'
import {
    createTrainDetailRequests,
    transformConnectingTrains,
    transformTrainDetail,
    transformWagonOrder,
} from '@/transforms'
import {updateTrainInfo} from '@common/helper'

import type {TrainDetailData} from '@/components/trainDetail/TrainDetailMixin'

@Component({
    name: 'TrainDetail',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        TabNavigation,
        TrainDetailStation,
    },
})
export default class TrainDetail extends Vue {
    @PropSync('train', {type: Object, default: null})
    private innerTrain!: Dbi.Train | null

    details: Dbi.TrainDetail | null = null
    wagonOrder: Dbi.WagonOrder | null = null
    connections: Dbi.ConnectingTrain[] | null = null
    scrollElement: Element | null = null

    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'Zurück',
            onClick: this.close.bind(this),
        },
        right: {
            icon: 'menu',
            title: 'Menü',
            onClick: () => {
                this.$dbiStore.mutations.menuOpen(true)
            },
        },
    }

    get tabs(): TabNavigationLink[] {
        return [
            {
                component: TrainDetailRoute,
                icon: 'route',
                title: 'Fahrtverlauf',
                notification: this.details?.routeChanged && {type: 'warning'},
            },
            {
                component: TrainDetailWagonOrder,
                icon: 'wagon-order',
                title: 'Wagenreihung',
                notification: this.wagonOrder?.reversed && {type: 'warning'},
            },
            {
                component: TrainDetailConnections,
                icon: 'connections',
                title: 'Anschlusszüge',
            },
            {
                component: TrainDetailHim,
                icon: 'warning',
                title: 'HIM-Nachrichten',
            },
            {component: TrainDetailTp, icon: 'tp-notifications', title: 'TP-Nachrichten'},
            {
                component: TrainDetailCertificate,
                icon: 'qr-code',
                title: 'Bescheinigungen',
            },
        ]
    }

    get trainData(): {scrollElement: Element; trainData: TrainDetailData} {
        const {innerTrain: train, details, wagonOrder, connections} = this
        return {
            scrollElement: this.$el,
            trainData: {
                train: train as Dbi.Train,
                details,
                wagonOrder,
                connections,
            },
        }
    }

    mounted(): void {
        this.scrollElement = this.$el
    }

    close(): void {
        this.innerTrain = null
    }

    @Watch('innerTrain', {immediate: true})
    requestTrainDetails(train: Dbi.Train): void {
        this.details = null
        this.wagonOrder = null
        this.connections = null

        if (!train) {
            return
        }

        const requests = createTrainDetailRequests(train)
        this.$queue.sendBulk(requests)
    }

    @Subscription('TrainDetail')
    gotTrainDetail(response: DbiApi.TrainDetailResponse): void {
        if (!(response.success || response.success === undefined)) {
            this.$log.info(response.message)
            return
        }
        if (!this.innerTrain) {
            return
        }
        this.details = transformTrainDetail(response, this.innerTrain.station.eva)
        updateTrainInfo(this.innerTrain, this.details)
    }

    @Subscription('WagonOrder')
    gotWagonOrder({trainInfo}: DbiApi.WagonOrderResponse): void {
        this.wagonOrder = transformWagonOrder(trainInfo)
    }

    @Subscription('ConnectingTrains')
    gotConnectingTrains({connections}: DbiApi.ConnectingTrainsResponse): void {
        this.connections = transformConnectingTrains(connections)
    }
}
