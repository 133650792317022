



























import {Component, Mixins} from 'vue-property-decorator'

import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'
import TrainDetailMixin from '@/components/trainDetail/TrainDetailMixin'

@Component({
    name: 'TrainDetailConnections',
    components: {
        TrainTime,
        TrainTrack,
    },
})
export default class TrainDetailConnections extends Mixins(TrainDetailMixin) {
    get trains(): Dbi.ConnectingTrain[] | null {
        return this.trainData.connections
    }
}
