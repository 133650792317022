var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dbi-layer',{staticClass:"station-panel-filter",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('dbi-layer-top-bar',{attrs:{"buttons":_vm.buttons}},[_vm._v("Filter")])]},proxy:true}])},[_vm._v(" "),_c('form',{on:{"submit":_vm.saveAndClose}},[_c('menu-form-station-select',{staticClass:"station-select",attrs:{"options":{type: 'S', resetable: true}},model:{value:(_vm.userSettings.station),callback:function ($$v) {_vm.$set(_vm.userSettings, "station", $$v)},expression:"userSettings.station"}},[_vm._v("\n            Standort\n        ")]),_vm._v(" "),_c('menu-form-select',{attrs:{"options":{
                id: 'diffBack',
                returnItemValue: true,
            },"items":_vm.timeWindowOptions},model:{value:(_vm.userSettings.diffBack),callback:function ($$v) {_vm.$set(_vm.userSettings, "diffBack", _vm._n($$v))},expression:"userSettings.diffBack"}},[_vm._v("\n            Nachlauf\n        ")]),_vm._v(" "),_c('menu-form-select',{attrs:{"options":{
                id: 'diffForth',
                returnItemValue: true,
            },"items":_vm.timeWindowOptions},model:{value:(_vm.userSettings.diffForth),callback:function ($$v) {_vm.$set(_vm.userSettings, "diffForth", _vm._n($$v))},expression:"userSettings.diffForth"}},[_vm._v("\n            Vorlauf\n        ")]),_vm._v(" "),_c('menu-form-select',{attrs:{"options":{
                id: 'delay',
                returnItemValue: true,
            },"items":_vm.delayOptions},model:{value:(_vm.userSettings.minDelay),callback:function ($$v) {_vm.$set(_vm.userSettings, "minDelay", _vm._n($$v))},expression:"userSettings.minDelay"}},[_vm._v("\n            Mindestverspätung\n        ")]),_vm._v(" "),_c('menu-form-checkboxes',{attrs:{"options":{
                id: 'traffictypes',
                itemValue: 'type',
            },"items":_vm.trafficTypes},model:{value:(_vm.userSettings.trafficTypes),callback:function ($$v) {_vm.$set(_vm.userSettings, "trafficTypes", $$v)},expression:"userSettings.trafficTypes"}},[_vm._v("\n            Verkehrsmittel\n        ")]),_vm._v(" "),_c('menu-form-select',{attrs:{"options":{
                id: 'halt',
                returnItemValue: true,
            },"items":_vm.haltOptions},model:{value:(_vm.userSettings.halt),callback:function ($$v) {_vm.$set(_vm.userSettings, "halt", _vm._n($$v))},expression:"userSettings.halt"}},[_vm._v("\n            Ankunft / Abfahrt\n        ")]),_vm._v(" "),_c('menu-form-select',{attrs:{"options":{
                id: 'travelState',
                returnItemValue: true,
            },"items":_vm.travelStateOptions},model:{value:(_vm.userSettings.travelState),callback:function ($$v) {_vm.$set(_vm.userSettings, "travelState", _vm._n($$v))},expression:"userSettings.travelState"}},[_vm._v("\n            Fahrt / Ausfall\n        ")]),_vm._v(" "),_c('menu-form-toggle',{attrs:{"options":{
                id: 'changedTracksOnly',
            }},model:{value:(_vm.userSettings.changedTracksOnly),callback:function ($$v) {_vm.$set(_vm.userSettings, "changedTracksOnly", _vm._n($$v))},expression:"userSettings.changedTracksOnly"}},[_vm._v("\n            Nur Gleisänderungen\n        ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }