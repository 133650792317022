
import type {FunctionalComponentOptions, VNode} from 'vue/types'

import {getStationMarking} from '@/transforms'

import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'

const component: FunctionalComponentOptions<{train: Dbi.Train; details?: Dbi.TrainDetail}> = {
    name: 'TrainDetailStation',
    functional: true,
    props: {
        train: {
            type: Object,
            required: true,
        },
        details: {
            type: Object,
        },
    },
    render(createElement, context): VNode[] | VNode {
        const {
            train,
            train: {canceled, arrival, departure, additional, track},
            details,
        } = context.props

        const childElements = []
        function addTime(time: Dbi.Train['arrival' | 'departure']) {
            if (time && !time.canceled) {
                childElements.push(createElement(TrainTime, {props: {time, tag: 'span'}}))
            }
        }

        addTime(arrival)
        const marking = getStationMarking(train, details)
        if (marking) {
            childElements.push(createElement('span', {staticClass: 'marking'}, marking))
        }
        addTime(departure)
        if (track) {
            childElements.push(
                createElement(TrainTrack, {
                    props: {
                        track,
                        onlyChanges: true,
                        tag: 'span',
                        showText: true,
                    },
                }),
            )
        }

        return createElement(
            'div',
            {
                staticClass: 'train-detail-station',
                class: {
                    changed: canceled || additional || arrival?.canceled || departure?.canceled,
                },
            },
            childElements,
        )
    },
}
export default component
