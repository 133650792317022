





























































import {Vue, Prop, PropSync, Component} from 'vue-property-decorator'

import {createCertificateHash} from '@common/helper'
import {createCertificateRequest} from '@/transforms'
import {displayDate} from '@common/filters'
import {URLS} from '@/const'
import {THRESHOLD_TIMEOUT} from '@common/const'
import {Subscription} from '@/decorators'

import DbiLoader from '@common/components/ui/DbiLoader.vue'
import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import IconButton from '@common/components/baseComponents/IconButton.vue'
import QrCode from '@/components/misc/QrCode.vue'
import TrainDetailCertificateTime from '@/components/trainDetail/TrainDetailCertificateTime.vue'

@Component({
    name: 'TrainDetailCertificateLayer',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        DbiLoader,
        IconButton,
        QrCode,
        TrainDetailCertificateTime,
    },
})
export default class TrainDetailCertificateLayer extends Vue {
    @PropSync('open', {type: Boolean, required: true})
    private certificateOpen!: boolean

    @Prop({type: Object, required: true})
    private readonly data!: {
        train: Dbi.Train
        station: Dbi.Station
        variant: Dbi.CertificateVariant
    }

    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.close.bind(this),
        },
    }

    loading = false
    error = false
    timeoutId: ReturnType<typeof setTimeout> | null = null

    get title(): string {
        return this.data.variant === 'delay' ? 'Verspätungsbescheinigung' : 'Ausfallbescheinigung'
    }

    get train(): Dbi.Train {
        return this.data.train
    }

    get variant(): string {
        return this.data.variant === 'delay' ? 'der Verspätung' : 'des Ausfalls'
    }

    get intro(): string {
        const {
            variant,
            station,
            train: {name, time},
        } = this.data
        const type = variant === 'delay' ? 'die Verspätung' : 'den Ausfall'
        return `Bescheinigung über ${type} des Zuges ${name} in ${station.name} am ${displayDate(time)}.`
    }

    get code(): string {
        const {train, station, variant} = this.data
        return createCertificateHash(train, station, variant)
    }

    get url(): string {
        return URLS.DELAY_CERTIFICATES + this.code
    }

    get show(): Record<'arrival' | 'departure', boolean> {
        const {
            variant,
            train: {arrival, departure},
        } = this.data
        const {delayCertificate} = this.$dbiStore.state.threshold
        const showFnc = (timeEntry?: Dbi.TimeEntry): boolean => {
            return !!timeEntry && (variant !== 'delay' || timeEntry.diff >= delayCertificate)
        }
        return {
            arrival: showFnc(arrival),
            departure: showFnc(departure),
        }
    }

    created(): void {
        this.loading = true
        const payload = createCertificateRequest({
            ...this.data,
            qrCode: this.code,
        })

        this.$queue.send('Certificate', payload)

        this.timeoutId = setTimeout(this.requestTimedout, THRESHOLD_TIMEOUT)
    }

    requestTimedout(): void {
        this.timeoutId = null
        if (this.loading) {
            this.error = true
        }
        this.loading = false
    }

    @Subscription('Certificate')
    gotCertificate(data: DbiApi.CertificateResponse): void {
        if (this.timeoutId !== null) {
            clearTimeout(this.timeoutId)
            this.timeoutId = null
        }

        this.loading = false
    }

    close(): void {
        this.certificateOpen = false
    }
}
