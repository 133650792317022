
import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'
import {FunctionalComponentOptions, VNode} from 'vue'

const component: FunctionalComponentOptions<{stop: Dbi.TrainDetailStop}> = {
    name: 'TrainRouteRow',
    functional: true,
    props: {
        stop: {
            type: Object,
            required: true,
        },
    },
    render(createElement, context): VNode {
        const {
            changed,
            currentStation,
            trainAtStation,
            trainBeforeStation,
            station: {name},
            markings,
            arrival,
            departure,
            track,
        } = context.props.stop
        const params: Record<string, unknown> = {
            class: {
                changed,
                'current-station': currentStation,
                'at-station': trainAtStation,
                'before-station': trainBeforeStation,
            },
        }
        if (trainAtStation || trainBeforeStation) {
            params.attrs = {id: 'train-position'}
        }
        return (
            <tr {...params}>
                <td class="indicator"></td>
                <th scope="row" class="station">
                    {name}
                    {markings && markings.map((marking) => <span class="marking">{marking}</span>)}
                </th>
                {createElement(TrainTime, {
                    props: {time: arrival, showCancelText: false, twoRows: true},
                })}
                {createElement(TrainTime, {
                    props: {time: departure, showCancelText: false, twoRows: true},
                })}
                {createElement(TrainTrack, {props: {track}})}
            </tr>
        ) as VNode
    },
}
export default component
