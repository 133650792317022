




























import {Component, Prop, Mixins, Ref} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import DbiCheckbox from '@common/components/form/DbiCheckbox.vue'
import {InputMixin, InputMixinOptions} from '@common/components/form/inputMixin'

export type MenuFormCheckboxesOptions = InputMixinOptions & {
    itemTitle?: string
    itemTitleShort?: string
    itemValue?: string
}

const OPTION_DEFAULTS = {
    itemTitle: 'title',
    itemTitleShort: 'short',
    itemValue: 'value',
}

// FIXME:
// since "Record<string, unknown>" is ValueType find a way to use that
@Component({
    name: 'MenuFormCheckboxes',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        DbiCheckbox,
    },
})
export default class MenuFormCheckboxes<ValueType extends Array<Record<string, unknown>>> extends Mixins<
    InputMixin<MenuFormCheckboxesOptions, Array<Record<string, unknown>>>
>(InputMixin) {
    @Prop({type: Array, required: true})
    private readonly items!: ValueType

    @Ref()
    private readonly legend!: HTMLLegendElement

    open = false
    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.close.bind(this),
        },
    }

    get innerModel(): ValueType {
        return this.value as ValueType
    }

    set innerModel(value: ValueType) {
        this.$emit('change', value)
        this.$emit('input', value)
    }

    get innerOptions(): MenuFormCheckboxesOptions {
        return Object.assign({}, OPTION_DEFAULTS, this.options)
    }

    get itemValue(): keyof ValueType[number] {
        return this.innerOptions.itemValue as keyof ValueType[number]
    }

    get itemTitle(): keyof ValueType[number] {
        return this.innerOptions.itemTitle as keyof ValueType[number]
    }

    get selectedItems(): string {
        const {items, value, itemValue} = this
        if (items.length === value.length) {
            return 'Alle'
        }
        return (
            items
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                .filter((item) => value.includes(item[itemValue as any] as any))
                .map(({short}) => short)
                .join(', ')
        )
    }

    onClick(): void {
        this.open = true
    }

    close(): void {
        this.open = false
    }
}
