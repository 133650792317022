import {Vue, Component, Prop} from 'vue-property-decorator'

export type TrainDetailData = {
    train: Dbi.Train
    details: Dbi.TrainDetail | null
    wagonOrder: Dbi.WagonOrder | null
    connections: Dbi.ConnectingTrain[] | null
}

@Component
export default class TrainDetailMixin extends Vue {
    @Prop({type: Object, required: true})
    protected readonly trainData!: TrainDetailData
}
