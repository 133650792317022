





























































































import {Vue, PropSync, Component} from 'vue-property-decorator'

import DbiLayer from '@/components/layout/DbiLayer.vue'
import DbiLayerTopBar from '@/components/layout/DbiLayerTopBar.vue'
import MenuFormStationSelect from '@/components/form/MenuFormStationSelect.vue'
import MenuFormSelect from '@/components/form/MenuFormSelect.vue'
import MenuFormCheckboxes from '@/components/form/MenuFormCheckboxes.vue'
import MenuFormToggle from '@/components/form/MenuFormToggle.vue'

import {TRAFFIC_TYPES} from '@common/const'
import {HALT_OPTIONS, TRAVELSTATE_OPTIONS} from '@/const'

function numberToTime(arr: number[]): {value: number; title: string}[] {
    return arr.map((value) => ({
        value,
        title: `${value} Minuten`,
    }))
}
const TIME_OPTIONS = [5, 10, 15, 30, 45, 60, 90, 120]
const TIME_WINDOW_OPTIONS = numberToTime([0, ...TIME_OPTIONS])
const DELAY_OPTIONS = [{value: 0, title: 'Keine'}, ...numberToTime(TIME_OPTIONS)]

@Component({
    name: 'StationPanelFilter',
    components: {
        DbiLayer,
        DbiLayerTopBar,
        MenuFormStationSelect,
        MenuFormSelect,
        MenuFormCheckboxes,
        MenuFormToggle,
    },
})
export default class StationPanelFilter extends Vue {
    @PropSync('open', {type: Boolean, required: true})
    private filterOpen!: boolean

    timeWindowOptions = TIME_WINDOW_OPTIONS
    delayOptions = DELAY_OPTIONS
    trafficTypes = TRAFFIC_TYPES
    haltOptions = HALT_OPTIONS
    travelStateOptions = TRAVELSTATE_OPTIONS

    userSettings = {}
    buttons = {
        left: {
            icon: 'arrow-left',
            title: 'zurück',
            onClick: this.saveAndClose.bind(this),
        },
        right: {
            icon: 'reset',
            title: 'zurücksetzen',
            type: 'reset',
            onClick: this.reset.bind(this),
        },
    }

    created(): void {
        this.getUserSettings()
    }

    getUserSettings(): void {
        this.userSettings = {...this.$dbiStore.state.stationPanel}
    }

    saveAndClose(): void {
        this.$dbiStore.actions.stationPanelSettings(this.userSettings)
        this.filterOpen = false
    }

    reset(): void {
        this.$dbiStore.actions.resetStationPanelSettings()
        this.getUserSettings()
    }
}
