
































































import {Component, Mixins} from 'vue-property-decorator'

import Sector from '@/components/trainDetail/Sector.vue'
import Wagon from '@/components/trainDetail/Wagon.vue'
import TrainDetailMixin from '@/components/trainDetail/TrainDetailMixin'

@Component({
    name: 'TrainDetailWagonOrder',
    components: {
        Wagon,
        Sector,
    },
})
export default class TrainDetailWagonOrder extends Mixins(TrainDetailMixin) {
    get wagonOrder(): Dbi.WagonOrder | null {
        return this.trainData.wagonOrder
    }
}
