
import {FunctionalComponentOptions, VNode} from 'vue'

import TrainTime from '@common/components/misc/TrainTime.vue'
import TrainTrack from '@common/components/misc/TrainTrack.vue'

const component: FunctionalComponentOptions<{train: Dbi.Train}> = {
    name: 'StationPanelRow',
    functional: true,
    props: {
        train: {
            type: Object,
            required: true,
        },
    },
    render(createElement, context): VNode[] | VNode {
        const {
            name,
            specificName,
            origin,
            destination,
            delayed,
            canceled,
            timeVerified,
            arrival,
            departure,
            track,
            hasNotification,
        } = context.props.train
        const indicatorClasses = {
            indicator: true,
            delayed,
            canceled,
            prognosis: !timeVerified,
        }
        return (
            <tr click="showDetails" {...{on: context.listeners}}>
                <td class={indicatorClasses}></td>
                <th scope="row" class="name">
                    {specificName && <span>{specificName}</span>}
                    {name}
                </th>
                <td class="journey">
                    <span class="origin">{origin.name}</span>
                    <span class="destination">{destination.name}</span>
                </td>
                {createElement(TrainTime, {props: {time: arrival, twoRows: true}})}
                {createElement(TrainTime, {props: {time: departure, twoRows: true}})}
                {createElement(TrainTrack, {props: {track}})}
                <td class="notification">
                    {hasNotification && <svg-sprite icon="exclamation-big" title="Es liegen Hinweise vor" />}
                </td>
            </tr>
        ) as VNode
    },
}
export default component
