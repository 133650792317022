




















import {Component, Prop, Vue, Model} from 'vue-property-decorator'

export type Options = {
    id: string
    name?: string
    // readonly?: boolean
}

@Component({
    name: 'MenuFormToggle',
})
export default class MenuFormToggle extends Vue {
    @Model('input', {type: Boolean})
    private readonly value!: boolean

    @Prop({type: Object, required: true})
    readonly options!: Options

    get innerOptions(): Options {
        return Object.assign({}, this.options)
    }

    get innerName(): string {
        const {id, name} = this.innerOptions
        return name || id
    }

    get innerModel(): boolean {
        return this.value
    }

    set innerModel(value: boolean) {
        this.$emit('change', value)
        this.$emit('input', value)
    }
}
