






















































import {Vue, Watch, Component} from 'vue-property-decorator'

import type {StationPanelSettings} from '@/store/state'

import StationPanelRow from '@/components/stationPanel/StationPanelRow.vue'
import StationPanelFilter from '@/components/stationPanel/StationPanelFilter.vue'
import StationSelect from '@/components/form/StationSelect.vue'
import TrainDetail from '@/components/trainDetail/TrainDetail.vue'

import {Subscription} from '@/decorators'
import {transformTrains} from '@/transforms'
import {applyFilterArray, formatDate} from '@common/lib'

@Component({
    name: 'StationPanel',
    components: {
        StationPanelRow,
        StationPanelFilter,
        StationSelect,
        TrainDetail,
    },
})
export default class StationPanel extends Vue {
    showSelect = false
    filterOpen = false
    now!: Date

    trains: Dbi.Train[] = []

    buttons = {
        left: {
            icon: 'filter',
            title: 'Filter',
            onClick: this.toggleFilter.bind(this),
        },
    }

    selectedTrain: Dbi.Train | null = null

    get station(): StationPanelSettings['station'] {
        return this.$dbiStore.state.stationPanel.station
    }

    set station(station: StationPanelSettings['station']) {
        this.showSelect = false
        this.$dbiStore.mutations.stationPanelSettings({station})
    }

    get filteredTrains(): Dbi.Train[] {
        return applyFilterArray(this.trains, this.$dbiStore.getters.stationPanelFilterArray)
    }

    get sortedTrains(): {
        back: Dbi.Train[]
        forth: Dbi.Train[]
        } {
        const {now, filteredTrains} = this
        const back: Dbi.Train[] = []
        const forth: Dbi.Train[] = []
        filteredTrains.forEach((train) => {
            ;(train.time < now ? back : forth).push(train)
        })
        return {back, forth}
    }

    get settings(): {stationId: string; diffBack: number; diffForth: number} {
        const {
            station: {eva},
            diffBack,
            diffForth,
        } = this.$dbiStore.state.stationPanel
        return {stationId: eva, diffBack, diffForth}
    }

    @Subscription('StationPanel')
    gotStationPanelResponse({trains}: DbiApi.StationPanelResponse): void {
        this.trains = transformTrains(trains)
    }

    @Watch('settings', {immediate: true})
    update(): void {
        const now = (this.now = new Date())
        now.setSeconds(0)
        now.setMilliseconds(0)
        const {stationId, diffBack, diffForth} = this.settings
        const payload = {
            stationId,
            searchDate: formatDate(now),
            diffBack,
            diffForth,
        }
        this.$queue.send('StationPanel', payload)
    }

    showTrainDetail(train: Dbi.Train): void {
        this.selectedTrain = train
    }

    titleClicked(event: MouseEvent): void {
        event.preventDefault()
        this.showSelect = true
    }

    closeStationSelect(): void {
        this.showSelect = false
    }

    toggleFilter(): void {
        this.filterOpen = !this.filterOpen
    }

    public handleBottomBarClick(): void {
        if (this.selectedTrain) {
            this.selectedTrain = null
        }
        else if (window.scrollY !== 0) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
            })
        }
        else {
            this.update()
        }
    }
}
