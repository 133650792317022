

























import {FunctionalComponentOptions} from 'vue'

const component: FunctionalComponentOptions<{
    train: string
    station: string
    disabled?: boolean
}> = {
    name: 'TrainDetailCertificateButton',
    functional: true,
    props: {
        train: {
            type: String,
            required: true,
        },
        station: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
export default component
