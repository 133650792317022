






























import {Component, Mixins} from 'vue-property-decorator'

import TrainDetailMixin from '@/components/trainDetail/TrainDetailMixin'
import TrainDetailCertificateButton from '@/components/trainDetail/TrainDetailCertificateButton.vue'
import TrainDetailCertificateLayer from '@/components/trainDetail/TrainDetailCertificateLayer.vue'

import {isDelayed} from '@/helper'

@Component({
    name: 'TrainDetailCertificate',
    components: {
        TrainDetailCertificateButton,
        TrainDetailCertificateLayer,
    },
})
export default class TrainDetailCertificate extends Mixins(TrainDetailMixin) {
    certificateOpen = false
    variant: Dbi.CertificateVariant = 'delay'

    get train(): Dbi.Train {
        return this.trainData.train
    }

    get station(): Dbi.Station {
        return this.$dbiStore.state.stationPanel.station
    }

    get delayed(): boolean {
        const {delayCertificate} = this.$dbiStore.state.threshold
        const {arrival, departure} = this.train
        return isDelayed(arrival, delayCertificate) || isDelayed(departure, delayCertificate)
    }

    handleBtnClick(variant: Dbi.CertificateVariant): void {
        this.variant = variant
        this.certificateOpen = true
    }
}
